/* defult css .................................................................................................................. */
html {
    scroll-behavior: smooth;
}

main{
    animation: zoomin 0.5s ease-in-out;
}

@keyframes zoomin{
    0%{
        opacity: 0;
        scale: 0.9;
    }  

    100%{
        opacity: 1;
        scale: 1;
    }
}

*{
    box-sizing: border-box;
}


a{
    color: black;
    text-decoration: none;
    transition: 0.5s;
}


li{
    list-style-type: none;
    padding: 0rem;
    margin: 0rem;
}


body{
    overflow-x: hidden !important;
}


main{
    overflow-x: hidden !important;
}


.hide-pc{
    display: none !important;
}


.hide-mobile{
    display: flex !important;
}


input:focus{
    outline: none;
}


textarea:focus{
    outline: none;
}


.input-hover{
    transition: 0.3s;
}


.input-hover:hover{
    border: 1px solid rgba(189, 189, 199) !important;
}


.input-hover:focus{
    border: 1px solid #157EC1 !important;
}


.list-style-type ul{
    margin: 1rem 0rem;
}


.list-style-type li{
    margin-left: 2rem !important;
    padding-left: 0rem !important;
    list-style-type: circle !important;
}


/* ......................................................................................................................... ..*/


#aside-home{
    display: flex;
    justify-content: top;
    align-items: flex-start;
    flex-direction: column;
    position: fixed;
    left: 0;
    top: 0;
    overflow-x: auto;
    width: 14rem;
    height: 100vh;
    max-height: 100vh;
    background-color: white;
    border-right: 2px solid #F0F0F0;
    padding: 32px 24px 16px 24px;
    z-index: 999;
}


#aside-home .menu-sidbar{
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 1.5rem;
    margin-bottom: 1.5rem;
}


#aside-home .menu-sidbar li a{
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 1.1rem;
    color: #7A7A7A;
    margin: 2rem 0rem;
}


#aside-home .menu-b-sidbar li{
    font-weight: 400;
    color: #525252;
    margin: 1rem 0rem;
}


#aside-home .menu-sidbar li a span{
    margin-left: 0.5rem;
}


#aside-home .menu-sidbar li a svg{
    width: 25px;
    height: 25px;
}


#aside-home .menu-sidbar .ative-menu{
    font-weight: 600;
    color: black;
}


#aside-home .menu-sidbar .ative-menu svg path[fill="#7A7A7A"]{
    fill: black;
}


#aside-home .menu-sidbar .ative-menu svg path[stroke="#7A7A7A"]{
    stroke: black;
}


#nav-menu-main{
    display: flex;
    justify-content: top;
    align-items: flex-start;
    position: fixed;
    width: 100vw;
    height: 4rem;
    bottom: 0;
    left: 0;
    background-color: white;
    border-top: 2px solid #F0F0F0;
    z-index: 999;
}


#nav-menu-main ul{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.5rem;
}


#nav-menu-main li{
    width: 25%;
    min-width: 25%;
    height: 100%;
}


#nav-menu-main li a{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-weight: 400;
    font-size: 12px;
    color: #7A7A7A;
    height: 100%;
}


#nav-menu-main li{
    font-weight: 400;
    color: #525252;
    margin: 1rem 0rem;
    transition: 0.5s;
}


#nav-menu-main li a span{
    margin-top: 0.2rem;
    text-wrap: nowrap !important;
}


#nav-menu-main li a svg{
    width: 25px;
    height: 25px;
}


#nav-menu-main .ative-menu{
    font-weight: 500;
    color: black;
}


#nav-menu-main .ative-menu svg path[fill='#7A7A7A']{
    fill: black;
}


#nav-menu-main .ative-menu svg path[stroke='#7A7A7A']{
    stroke: black;
}


#main-home{
    width: calc(100vw - 14rem);
    min-height: 100vh;
    margin-left: 14rem;
    padding: 2rem;
    display: flex;
    align-items: flex-start;
}


#main-posts{
    width: calc(100vw - 14rem);
    min-height: 100vh;
    margin-left: 14rem;
    padding: 2rem 14rem;
}


.main-slider-home{
    height: 100vh;
}


.main-slider-home .swiper-slide{
    height: auto !important;
}


#main-posts .content img{
    width: 100%;
    border-radius: 1rem;
    margin: 1rem 0rem;
}


.slider-news-post > div{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}


.slider-news-post .swiper-pagination{
    background-color: rgba(0, 0, 0, 0.281);
    border-radius: 100px;
    padding: 0.1rem 0.3rem;
    bottom: 1rem !important;
    width: max-content !important;
    left: auto !important;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1.5rem;
}


.swiper-pagination-lock{
    display: none !important;
}


.slider-news-post .swiper-pagination .swiper-pagination-bullet-active{
    background-color: white;
    opacity: 1 !important;
}


.slider-news-post .swiper-pagination .swiper-pagination-bullet{
    background-color: white;
    opacity: 0.4;
}


.slider-news-post .swiper-button-prev{
    background-color: white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100px;
    opacity: 1 !important;
    font-size: 15px !important;
    border: 1px solid #C0C2C5;
    display: flex;
    justify-content: center;
    align-items: center;
}


.slider-news-post .swiper-button-prev::after{
    font-size: 20px;
    font-weight: 900;
    margin-left: -0.2rem;
    color: black;
}


.slider-news-post .swiper-button-next{
    background-color: white;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100px;
    opacity: 1 !important;
    font-size: 15px !important;
    border: 1px solid #C0C2C5;
    display: flex;
    justify-content: center;
    align-items: center;
}


.slider-news-post .swiper-button-next::after{
    font-size: 20px;
    font-weight: 900;
    margin-right: -0.2rem;
    color: black;
}


.swiper-button-lock{
    display:none !important;   
}


@media screen and (max-width:1250px){
    #main-posts{
        padding: 2rem 8rem;
    }

    #main-home > div:nth-child(1){
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        margin-right: 2rem;
    }
}


@media screen and (max-width:1024px){
    .hide-pc{
        display: flex !important;
    }


    .hide-mobile{
        display: none !important;
    }


    #header-main{
        width: 100%;
        border-bottom: 1px solid #E6E6E6;
        height: 5rem;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    #header-post{
        width: 100%;
        overflow-x: hidden;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }


    #main-home{
        width: 100vw;
        min-height: 100vh;
        margin-left: 0rem;
        margin-bottom: 4rem;
        padding: 1rem;
        align-items: flex-start;
        flex-direction: column;
    }


    #main-posts{
        width: 100vw;
        min-height: 100vh;
        margin-left: 0rem;
        margin-bottom: 4rem;
        padding: 1rem;
        align-items: flex-start;
        flex-direction: column;
        background-color: #F5F5F5;
    }

    .main-signup{
        min-height: calc(100vh - 8rem) !important;
    }

    .slider-news-post .swiper-button-prev{
        display: none;
    }

    .slider-news-post .swiper-button-next{
        display: none;
    }

    #main-home > div:nth-child(1){
        padding-left: 0rem;
        padding-right: 0rem;
        margin-right: 0rem;
    }

    .no-padding-mobile{
        padding: 1rem 0rem !important;
    }
}